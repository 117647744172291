<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-n2">
        <h4 style="font-weight: 500">Généralité</h4>
        </div>
        <hr class="" />
        <mdb-card class="z-depth-1"> 
            <mdb-card-body class="card-reversed">  
                <form @submit.prevent="updateCompany()" >
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" :disabled="!$gate.checkTypeRole(['super-admin'])"
                            :validation="$store.state.company.errors.name ? true :false" :invalidFeedback="$store.state.company.errors.name"
                             v-model="name" outline label="Raison Social" size="lg" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                            :validation="$store.state.company.errors.ifu ? true :false" :invalidFeedback="$store.state.company.errors.ifu"
                             v-model="ifu"  :disabled="true" outline label="IFU" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                            :validation="$store.state.company.errors.rccm ? true :false" :invalidFeedback="$store.state.company.errors.rccm"
                             v-model="rccm"  :disabled="true" outline label="RCCM" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" :disabled="!$gate.hasPermission(['edit::setting'])"
                            :validation="$store.state.company.errors.email ? true :false" :invalidFeedback="$store.state.company.errors.email"
                             v-model="form.email" type="email" outline label="Adresse mail" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6"> 
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" :disabled="!$gate.hasPermission(['edit::setting'])"
                            v-mask="'(###) ## ### ###'" placeholder="(000)00 000 000"
                            :validation="$store.state.company.errors.phone ? true :false" :invalidFeedback="$store.state.company.errors.phone"
                             v-model="form.phone" outline   label="Téléphone" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" :disabled="!$gate.hasPermission(['edit::setting'])"
                            :validation="$store.state.company.errors.address ? true :false" :invalidFeedback="$store.state.company.errors.address"
                             v-model="form.address" outline label="Adresse Physique" size="md" ></mdb-input>
                        </mdb-col>

                        <mdb-col sm="12" md="6" class="mt-2" v-if="$gate.hasPermission(['edit::setting'])">
                            <label class="color-normal-reversed" for="">Logo Entreprise</label>
                            <mdb-card class="card-logo " v-if="$store.state.auth.company.logo" style="width:100px;position:relative" >
                                <mdb-view >
                                    <a>
                                        <mdb-card-image  id="logo"   :src="$store.state.url+'storage/logo/'+$store.state.auth.company.logo" alt="Card image cap" ></mdb-card-image>
                                        <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                                    </a>
                                </mdb-view>
                            </mdb-card>
                            <el-upload
                                class="upload-demo mt-3"
                                ref="upload"
                                :action="$store.state.url+'api/companies/logo/'+this.form.id"
                                :headers="{
                                'Authorization':'Bearer '+$store.state.auth.token}"
                                :accept="'image/png'"
                                :limit="1"
                                :auto-upload="true"
                                :on-success="save_logo_success"
                                :on-error="save_logo_error">
                                <el-button slot="trigger" size="small" type="primary">Ajouter</el-button>
                                <el-button v-if="$store.state.auth.company.logo" style="margin-left: 10px;" size="small" type="danger" @click="deleteLogo">Supprimer</el-button>
                                <div class="el-upload__tip colo-reversed" slot="tip">Fichiers png avec une taille inférieure à 5000kb</div>
                            </el-upload>
                            
                        </mdb-col>

                        <mdb-col   sm="12" class="d-flex justify-content-end" style="margin-top:40px">
                            <button   v-if="$gate.hasPermission(['edit::setting'])" type="submit" :disabled="submitBtn" class="btn btn-primary btn-md" > 
                                <span v-if="!submitBtn">Sauvegarder</span>
                                <span v-if="submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-if="submitBtn" class="pl-2">Loading...</span>
                             </button>
                        </mdb-col>
                    </mdb-row>
                </form>
                    
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
    mdbInput,
    mdbRow,mdbCol,
    mdbCard,mdbCardBody,
    mdbCardImage
} from 'mdbvue';
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - PARAMETRE GENERALITE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbInput,
        mdbRow,mdbCol,
        mdbCard,mdbCardBody,
        mdbCardImage
    },
    data(){
        return {
            submitBtn:false,
            ifu:this.$store.state.auth.company.ifu,
            rccm:this.$store.state.auth.company.rccm,
               name:this.$store.state.auth.company.name,

            form:{
               id:this.$store.state.auth.company.id,
               email:this.$store.state.auth.company.email,
               phone:this.$store.state.auth.company.phone,
               address:this.$store.state.auth.company.address,
            //    logo:this.$store.state.auth.company.logo,
            } 
        }
    },

    methods:{
        async updateCompany() {
            this.$nprogress.start()
            this.$store.commit('company/SET_CLEAN')
            this.submitBtn = !this.submitBtn

            this.form.ifu = this.ifu
            this.form.rccm = this.rccm
            this.form.name = this.name
			await this.$store.dispatch('company/update', this.form)
			.then((response) => {
                this.$nprogress.done()

                this.submitBtn = !this.submitBtn
                this.$notify({
                     
                    message: response.data.message,
                    type: 'success'
                })
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
			})
			.catch((error) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                if (error.response.data.errors) {
                    this.$store.commit('company/SET_NAME', error.response.data.errors.name)
                    this.$store.commit('company/SET_EMAIL', error.response.data.errors.email)
                    this.$store.commit('company/SET_PHONE', error.response.data.errors.phone)
                    this.$store.commit('company/SET_ADDR', error.response.data.errors.address) 
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        submitUpload(){
            this.$refs.upload.submit();
        },

        save_logo_success(response){
            this.$refs.upload.clearFiles()
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
                this.$notify({
                     
                    message:response.message,
                    type:'success'
                })
        },
        save_logo_error(){
            this.$refs.upload.clearFiles()
                this.$notify({
                       
                    message:"Erreur de validation. verifier le fichier, son type et sa taille",
                    type:'danger'
                })
        },

        async deleteLogo(){
            let res = await this.$store.dispatch("swal/ultimate",{
                title:"Etes-vous sûre?",
                type:"warning",
                icon:"warning"
            });

            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('company/deleteLogo',this.$store.state.auth.company.id)
                .then((response) => {
                    this.$notify({
                         
                        message: response.data.message,
                        type: 'success'
                    })
                    this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
                })
                .catch(error =>{
                    this.$notify({
                           
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
        }
    },

    created() {
        
    },
}
</script>